import { useEffect } from "react";

export function useKeyPress(keyCode: string, callback: () => void) {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // Do not want to override areas where users will need to type
      if (["INPUT", "TEXTAREA"].includes((e.target as HTMLElement).tagName)) {
        return;
      }

      e.preventDefault();
      if ([keyCode].includes(e.code)) {
        callback();
      }
    };

    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [callback]);
}
