import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import convertTimeToString from "helpers/convertTimeToString";
import { useSelector } from "react-redux";
import { ProgressBarSlider } from "./ProgressBarSlider";
import { LoopingProgressBar } from "./LoopingProgressBar";

const ProgressBarWrapper = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  padding: 5px 0;
`;

const SliderWrapper = styled.div`
  margin: 0 10px;
  width: 100%;
`;

const Duration = styled.div`
  font-size: 14px;
  flex-basis: 130px;
  padding-left: 8px;
`;

const ProgressBar = ({
  currentTimeInSeconds,
  onAfterChange,
  onAfterLoopingChange = null,
  totalDuration,
  disableLooping = false,
  ...rest
}) => {
  const { looping, loopingRange } = useSelector(
    ({ classPlayer }) => classPlayer
  );
  const [dragging, setDragging] = useState(false);
  const [range, setRange] = useState(loopingRange);
  const [value, setValue] = useState(currentTimeInSeconds);

  useEffect(() => {
    if (!dragging) {
      setRange([loopingRange[0], loopingRange[1]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [looping, currentTimeInSeconds, dragging]);

  useEffect(() => {
    if (!dragging) {
      setValue(currentTimeInSeconds);
    }
  }, [currentTimeInSeconds, dragging]);

  const duration = useMemo(() => {
    if (typeof totalDuration === "string") {
      return totalDuration;
    }
    if (typeof totalDuration === "number") {
      return convertTimeToString(totalDuration);
    }
    return "00:00";
  }, [totalDuration]);

  return (
    <ProgressBarWrapper>
      <Duration>
        {convertTimeToString(currentTimeInSeconds)}
        &nbsp;/&nbsp;
        {duration}
      </Duration>
      <SliderWrapper>
        <ProgressBarSlider
          looping={looping}
          tipFormatter={newValue => convertTimeToString(newValue)}
          value={value}
          onBeforeChange={() => {
            setDragging(true);
          }}
          onChange={setValue}
          onAfterChange={newValue => {
            if (onAfterChange) {
              onAfterChange(newValue);
            }
            setDragging(false);
          }}
          {...rest}
        />
        {!disableLooping && looping && (
          <LoopingProgressBar
            count={1}
            pushable
            tipFormatter={newValue => convertTimeToString(newValue)}
            value={range}
            onBeforeChange={() => {
              setDragging(true);
            }}
            onChange={setRange}
            onAfterChange={newValue => {
              if (onAfterLoopingChange) {
                onAfterLoopingChange(newValue);
              }
              setDragging(false);
            }}
            {...rest}
          />
        )}
      </SliderWrapper>
    </ProgressBarWrapper>
  );
};

ProgressBar.defaultProps = {
  currentTimeInSeconds: 0,
  onAfterChange: null,
  totalDuration: "0:00",
};

ProgressBar.propTypes = {
  currentTimeInSeconds: PropTypes.number,
  disableLooping: PropTypes.bool,
  looping: PropTypes.bool.isRequired,
  onAfterChange: PropTypes.func,
  totalDuration: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default ProgressBar;
