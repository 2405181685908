import { Range, createSliderWithTooltip } from "rc-slider";
import styled from "styled-components";
import "rc-slider/assets/index.css";

const RangeWithTooltip = createSliderWithTooltip(Range);

export const LoopingProgressBar = styled(RangeWithTooltip)`
  &.rc-slider {
    padding: 0;
    border-radius: 0;
    height: 0;
  }
  .rc-slider-handle {
    top: -21px;
    width: 2px;
    height: 26px;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.gold};
    border: none;
    margin-left: 0;
    :after {
      position: absolute;
      top: -9px;
      left: -9px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.gold} transparent;
      border-width: 15px 10px 0;
      content: "";
    }
    :focus {
      border-color: ${({ theme }) => theme.colors.white};
      box-shadow: none;
    }
    :hover {
      border-color: ${({ theme }) => theme.colors.white};
    }
    :active {
      border-color: ${({ theme }) => theme.colors.white};
      box-shadow: none;
    }
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
  }
  .rc-slider-rail {
    height: 0;
  }
  .rc-slider-track {
    height: 0;
  }
  .rc-slider-step {
    height: 0;
  }
`;
