import Slider, { createSliderWithTooltip } from "rc-slider";
import styled, { css } from "styled-components";
import "rc-slider/assets/index.css";

const progressBarLoopingStyling = css`
  &.rc-slider {
    padding: 0;
    border-radius: 0;
    height: 20px;
  }

  .rc-slider-handle {
    height: 0;
    width: 0;
  }

  .rc-slider-handle:focus {
    border-color: transparent;
    box-shadow: none;
  }

  .rc-slider-rail {
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0.4;
    border-radius: 0;
    height: 100%;
  }

  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0;
    height: 100%;
  }

  .rc-slider-step {
    height: 100%;
  }
`;

const StyledSlider = styled(Slider)`
  padding: 5px 0;
  border-radius: 0;

  &.rc-slider {
    height: 10px;
  }
  .rc-slider-vertical {
    padding: 0;
    width: 4px;
  }
  .rc-slider-rail {
    height: 8px;
    background-color: ${({ theme }: any) => theme.colors.white};
    border-radius: 0;
    opacity: 0.4;
  }
  .rc-slider-step {
    height: 8px;
  }
  .rc-slider-track {
    background-color: ${({ theme }: any) => theme.colors.white};
    border-radius: 0;
    height: 8px;
  }
  .rc-slider-handle {
    background-color: ${({ theme }: any) => theme.colors.white};
    border: none;
    height: 20px;
    width: 20px;
  }
  .rc-slider-handle:focus {
    border-color: ${({ theme }: any) => theme.colors.white};
    box-shadow: 0 0 0 3px ${({ theme }: any) => theme.colors.white};
  }
  .rc-slider-handle:hover {
    border-color: ${({ theme }: any) => theme.colors.white};
  }
  .rc-slider-handle:active {
    border-color: ${({ theme }: any) => theme.colors.white};
    box-shadow: 0 0 3px ${({ theme }: any) => theme.colors.white};
  }

  ${({ looping }: { looping: boolean }) => looping && progressBarLoopingStyling}
`;

export const ProgressBarSlider = createSliderWithTooltip(StyledSlider as any);
