import React, { useCallback, useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import {
  Fullscreen,
  FullscreenExit,
  Play,
  VolumeOn,
  VolumeOff,
  Rewind5,
  Rewind10,
  Forward5,
  Forward10,
  Pause,
  Forward15,
  Rewind15,
} from "app/components/Icon";
import IconButton from "app/components/Button/IconButton";
import screenfull from "screenfull";
import { toggleSettingsVolumeAction } from "modules/components";
import { useComponentsSettingsVolume } from "modules/selectors";
import { ReactPlayerProps } from "react-player";
import ResponsiveVideoPlayer from "./index";
import { VerticalSlider } from "./VerticalSlider";
import TooltipWithTimer from "../Tooltip/TooltipWithTimer";
import Tooltip from "../Tooltip";
import { ControlButton, ControlIcon } from "../ClassPlayer/components";
import ProgressBar from "../ClassPlayer/ProgressBar";
import { useKeyPress } from "./hooks";

const screenfullFunction = screenfull as any;

const RewindSkipIcon = {
  5: Rewind5,
  10: Rewind10,
  15: Rewind15,
};

const ForwardSkipIcon = {
  5: Forward5,
  10: Forward10,
  15: Forward15,
};

interface Props extends ReactPlayerProps {
  onProgress?: (progress: any) => void;
  onReady?: (player: any) => void;
  autoplay?: boolean;
  skipIncrement?: 5 | 10 | 15;
  url: string;
  CtaComponents?: () => JSX.Element;
  AlertComponent?: () => JSX.Element;
  volume?: number | null;
}

const PlayerWithControls = ({
  onProgress = () => null,
  autoplay: paramPlaying = false,
  onReady = () => null,
  url,
  skipIncrement = 5,
  CtaComponents,
  AlertComponent,
  volume: paramVolume,
  ...rest
}: Props) => {
  const playerWrapperRef = useRef();
  const settingsVolume = useComponentsSettingsVolume();
  const dispatch = useDispatch();
  const [playerProgressInSeconds, setPlayerProgressInSeconds] = useState(0);
  const [playing, setPlaying] = useState(paramPlaying);
  const [showVideoControls, setShowVideoControls] = useState(false);
  const [durationOfVideo, setDurationOfVideo] = useState(0);
  const [volume, setVolume] = useState(settingsVolume);
  const [volumeSliderVisible, setVolumeSliderVisible] = useState(false);
  const [playerInstance, setPlayerInstance] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideoControls(false);
      setVolumeSliderVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [showVideoControls]);

  useEffect(() => {
    setPlaying(paramPlaying);
  }, [paramPlaying, url]);

  const handleVolumeHover = useCallback(() => {
    setVolumeSliderVisible(true);
    setShowVideoControls(true);
  }, [setVolumeSliderVisible, setShowVideoControls]);

  const seekTo = useCallback(
    (seconds: number) => {
      const safeSeconds = Math.min(Math.max(0, seconds), durationOfVideo);
      setPlayerProgressInSeconds(safeSeconds);
      playerInstance.seekTo(safeSeconds);
    },
    [playerInstance, durationOfVideo, setPlayerProgressInSeconds]
  );

  const rewind = useCallback(() => {
    seekTo(playerInstance.getCurrentTime() - skipIncrement);
  }, [playerInstance, seekTo, skipIncrement]);

  const fastForward = useCallback(() => {
    seekTo(playerInstance.getCurrentTime() + skipIncrement);
  }, [playerInstance, seekTo, skipIncrement]);

  const handleOnSpace = useCallback(() => setPlaying(!playing), [playing]);
  const handleOnArrowUp = useCallback(() => {
    if (volume < 1) {
      const newVol = volume + 0.1;
      setVolume(newVol > 1 ? 1 : newVol);
    }
  }, [volume]);
  const handleOnArrowDown = useCallback(() => {
    if (volume > 0) {
      const newVol = volume - 0.1;
      setVolume(newVol > 0 ? newVol : 0);
    }
  }, [volume]);
  const setVolumeAndToggle = (newVolume: number) => {
    if (paramVolume !== null) {
      return;
    }
    setVolume(newVolume);
    dispatch(toggleSettingsVolumeAction(newVolume));
  };

  useKeyPress("Space", handleOnSpace);
  useKeyPress("ArrowUp", handleOnArrowUp);
  useKeyPress("ArrowDown", handleOnArrowDown);
  useKeyPress("ArrowLeft", rewind);
  useKeyPress("ArrowRight", fastForward);
  const playerVolume = paramVolume ?? volume;

  return (
    <Flex
      alignItems="center"
      ref={playerWrapperRef}
      position="relative"
      onMouseMove={() => setShowVideoControls(true)}
      overflow="hidden"
    >
      <Div width="100%" position="relative">
        <ResponsiveVideoPlayer
          showLoader
          playing={playing}
          volume={playerVolume}
          muted={!playerVolume}
          onError={(err: any) => {
            setPlaying(!(err && err.name === "NotAllowedError"));
          }}
          onReady={player => {
            setPlayerInstance(player);
            setPlaying(paramPlaying);
            onReady(player);
          }}
          onProgress={playerProgress => {
            setPlayerProgressInSeconds(playerProgress.playedSeconds);
            if (onProgress) {
              onProgress(playerProgress);
            }
          }}
          onDuration={(duration: number) => {
            setDurationOfVideo(duration);
          }}
          url={url}
          ChildControls={
            <>
              <Flex
                justifyContent="center"
                alignItems="center"
                position="absolute"
                left={0}
                top={0}
                height="100%"
                width="100%"
                cursor="pointer"
                opacity={playing ? 0 : 1}
                onClick={() => setPlaying(!playing)}
              >
                <IconButton
                  onClick={() => setPlaying(!playing)}
                  Icon={Play}
                  color="white"
                  width="54px"
                  height="54px"
                />
              </Flex>
              <Div
                position="absolute"
                height="100px"
                width="100%"
                bottom={showVideoControls ? 0 : "-100px"}
                backgroundImage="linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%)"
                transition="bottom 0.25s ease-in-out"
                maxHeight="100px"
              >
                {(CtaComponents || AlertComponent) && (
                  <Flex
                    position="absolute"
                    width="100%"
                    bottom="130px"
                    px="16px"
                    gap="16px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {CtaComponents && <CtaComponents />}
                    {AlertComponent && <AlertComponent />}
                  </Flex>
                )}
                <Flex position="absolute" bottom="16px" left="16px">
                  <Tooltip
                    getTooltipContainer={() => playerWrapperRef.current}
                    overlay={playing ? "Pause" : "Play"}
                  >
                    <ControlButton onClick={() => setPlaying(!playing)}>
                      <ControlIcon as={playing ? Pause : Play} />
                    </ControlButton>
                  </Tooltip>
                  <TooltipWithTimer
                    getTooltipContainer={() => playerWrapperRef.current}
                    visible={volumeSliderVisible && showVideoControls}
                    overlayClassName="player-controls modal-el"
                    onMouseEnter={handleVolumeHover}
                    onMouseMove={handleVolumeHover}
                    onMouseLeave={() => setVolumeSliderVisible(false)}
                    overlay={
                      <>
                        <Flex height="150px" justifyContent="center" m="15px 0">
                          <VerticalSlider
                            min={0}
                            max={1}
                            value={playerVolume}
                            defaultValue={0.5}
                            step={0.01}
                            onChange={(newVolume: number) =>
                              setVolumeAndToggle(newVolume)
                            }
                            tipFormatter={(value: number) =>
                              `${(value * 100).toFixed(0)}%`
                            }
                          />
                        </Flex>
                      </>
                    }
                  >
                    <ControlButton
                      disabled={!playerInstance}
                      data-tip
                      data-for="Volume"
                      onClick={() => setVolumeAndToggle(playerVolume ? 0 : 0.5)}
                    >
                      <ControlIcon as={playerVolume ? VolumeOn : VolumeOff} />
                    </ControlButton>
                  </TooltipWithTimer>
                  <Tooltip
                    getTooltipContainer={() => playerWrapperRef.current}
                    overlay={`Rewind ${skipIncrement}s`}
                  >
                    <ControlButton
                      disabled={!playerInstance}
                      data-tip
                      data-for="Rewind"
                      onClick={rewind}
                    >
                      <ControlIcon as={RewindSkipIcon[skipIncrement]} />
                    </ControlButton>
                  </Tooltip>
                  <Tooltip
                    getTooltipContainer={() => playerWrapperRef.current}
                    overlay={`Fast Forward ${skipIncrement}s`}
                  >
                    <ControlButton
                      disabled={!playerInstance}
                      data-tip
                      data-for="Fast forward"
                      onClick={fastForward}
                    >
                      <ControlIcon as={ForwardSkipIcon[skipIncrement]} />
                    </ControlButton>
                  </Tooltip>
                </Flex>
                <Flex position="absolute" bottom="16px" right="16px">
                  {screenfullFunction.enabled && (
                    <ControlButton
                      disabled={!playerInstance}
                      data-tip
                      data-for="Fullscreen"
                      onClick={() =>
                        screenfullFunction.toggle(playerWrapperRef.current)
                      }
                    >
                      <ControlIcon
                        as={
                          screenfullFunction.isFullscreen
                            ? FullscreenExit
                            : Fullscreen
                        }
                      />
                    </ControlButton>
                  )}
                </Flex>
                <Div position="absolute" width="100%" top={0} p="0 16px">
                  <ProgressBar
                    disableLooping
                    currentTimeInSeconds={playerProgressInSeconds}
                    onAfterChange={seekTo}
                    max={durationOfVideo}
                    totalDuration={durationOfVideo}
                  />
                </Div>
              </Div>
            </>
          }
          {...rest}
        />
      </Div>
    </Flex>
  );
};

export default PlayerWithControls;
