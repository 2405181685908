import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import React from "react";

class TooltipWithTimer extends React.Component {
  render() {
    const {
      mouseLeaveDelay,
      placement,
      onMouseEnter,
      onMouseMove,
      onMouseLeave,
      overlay,
      ...rest
    } = this.props;

    return (
      <Tooltip
        mouseLeaveDelay={mouseLeaveDelay}
        placement={placement || "top"}
        onMouseEnter={() => {
          clearTimeout(this.timer);
          onMouseEnter();
        }}
        onMouseMove={() => {
          clearTimeout(this.timer);
          onMouseMove();
        }}
        onMouseLeave={() => {
          this.timer = setTimeout(() => {
            onMouseLeave();
          }, 500);
        }}
        overlay={
          <div
            onMouseEnter={() => {
              clearTimeout(this.timer);
              onMouseEnter();
            }}
            onMouseMove={() => {
              clearTimeout(this.timer);
              onMouseMove();
            }}
            onMouseLeave={() => {
              this.timer = setTimeout(() => {
                onMouseLeave();
              }, 500);
            }}
          >
            {overlay}
          </div>
        }
        // overlay over Modal and other high z-index elements
        overlayStyle={{ zIndex: 99999999999, ...rest.overlayStyle }}
        {...rest}
      />
    );
  }
}

TooltipWithTimer.defaultProps = {
  mouseLeaveDelay: 0,
  placement: "top",
  onMouseEnter: () => null,
  onMouseMove: () => null,
};

TooltipWithTimer.propTypes = {
  mouseLeaveDelay: PropTypes.number,
  placement: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseLeave: PropTypes.func.isRequired,
  overlay: PropTypes.node.isRequired,
};

export default TooltipWithTimer;
